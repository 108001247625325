<template>
  <v-card flat class="rounded-lg pa-2" height="100%">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="ranking-content">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          hide-default-footer
          class="rounded-lg leaderboard-table"
          :loading="loading"
        >
          <template v-slot:[`item.rank`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="text-underlined">
              <user-badge-dialog
                :itemId="item.id"
                :buttonLabel="item.name"
                :badges="item.badges"
              ></user-badge-dialog>
            </span>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const userRepository = RepositoryFactory.get("user");

const UserBadgeDialog = () => import("../dialogs/UserBadgeDialog.vue");

export default {
  components: {
    UserBadgeDialog
  },
  data: () => ({
    title: "Leaderboard",
    headers: [
      {
        text: "Rank",
        align: "start",
        sortable: false,
        value: "rank"
      },
      {
        text: "Name",
        value: "name",
        sortable: false
      },
      {
        text: "Badge Count",
        value: "count",
        sortable: false,
        width: "1%"
      }
    ],
    items: [],
    loading: false,
    eventName: "reloadItems_leaderboard"
  }),
  methods: {
    async getData() {
      this.loading = true;

      let params = {
        per_page: 10, //get only top 10 on the leaderboard
        badge_leaderboard: true
      };

      await userRepository
        .get({ params })
        .then(response => {
          let {
            data: {
              responseData: { data: items }
            }
          } = response;

          this.items = items.map(item => {
            let badges = item.badges;

            return {
              name: `${item.first_name} ${item.last_name}`,
              count: badges.length || 0,
              badges: badges || []
            };
          });
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
          // this.items = userRepository.getDummyData();
        });
    },
    reloadItemsLeaderBoardEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.getData();
      });
    },
    setupEventBus() {
      this.reloadItemsLeaderBoardEventBus();
    }
  },
  mounted() {
    this.getData();
    this.setupEventBus();
  }
};
</script>

<style lang="scss" scoped>
::v-deep tr:last-child > th {
  border-bottom: none !important;
  color: #000 !important;
}

::v-deep tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 32px !important;
}

.text-underlined {
  text-decoration: underline;
}
</style>
